<template>
  <!-- 绑定权限 -->
  <div class="admin-view-container">
    <el-form
      ref="postForm"
      label-position="right"
      label-width="100px"
      :model="postData"
      :rules="rules"
      size="small"
      @submit.native.prevent="handleSubmit"
    >
      <el-form-item :label="labelStr" prop="addrId">
        <select-dict
          :dict="addrList"
          valueField="addrId"
          labelField="addrName"
          v-model="postData.addrId"
        ></select-dict>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit" :loading="loading"
          >提交</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  adminBinding,
  getAddrStreetList,
  getAddrCommunityList,
} from "@/api/index.js";
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      postData: {
        addrId: null,
        adminId: null,
      },
      labelStr: "绑定居委会",
      addrList: [],
      rules: {
        addrId: [{ required: true, trigger: "change", message: "请选择地址" }],
      },
    };
  },
  created() {
    if (this.$props.row.roleType == 2) {
      this.labelStr = "绑定街道";
      this.getStreets();
    }
    if (this.$props.row.roleType == 3) {
      this.labelStr = "绑定居委会";
      this.getCommunitys();
    }
    this.postData.addrId = this.$props.row.addrId || "";
    this.postData.adminId = this.$props.row.adminId;
  },
  methods: {
    getStreets() {
      getAddrStreetList().then((res) => {
        if (res.data.code == 200) {
          this.addrList = res.data.data;
        }
      });
    },
    getCommunitys() {
      getAddrCommunityList().then((res) => {
        if (res.data.code == 200) {
          this.addrList = res.data.data;
        }
      });
    },
    cancel() {
      this.$emit("end");
    },
    handleSubmit() {
      this.$refs["postForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$utils.resolveRequest(adminBinding(this.postData), this);
        } else {
          this.loading = false;
          this.$message.warning("请填写正确信息！");
          return false;
        }
      });
    },
  },
};
</script>